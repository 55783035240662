import { navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import useScreenWidth from '../../../hooks/useScreenWidth'
import useStaticImage from '../../../hooks/useStaticImage'
import categoriesData from './categories.json'
import './styles.scss'

const CasesRelational = ({
  relationalData,
  itsRelationalCase = false,
  currentCategory,
}) => {
  const [getImage] = useStaticImage()
  const { isMobile } = useScreenWidth()
  const mobileSlideLength = 2

  return (
    <section className="cases-relational">
      {itsRelationalCase ? (
        <div className="cases-relational__title">
          <h2>Cases relacionados</h2>
        </div>
      ) : (
        <div className="cases-relational__title">
          {isMobile ? (
            <h2>Conheça alguns cases</h2>
          ) : (
            categoriesData.flatMap((data) =>
              data.categories.find((category) => category.name === currentCategory) ? (
                <h2 key={currentCategory}>Conheça alguns cases de {currentCategory}</h2>
              ) : (
                <h2 key="default">Conheça alguns cases desta solução</h2>
              ),
            )
          )}
        </div>
      )}
      <div className="cases-relational__container">
        <div className="cases-relational__grid">
          {isMobile ? (
            <>
              {relationalData?.slice(0, mobileSlideLength)?.map((caseRelational) => (
                <div
                  className="cases-relational__slider"
                  onClick={() => navigate(`${caseRelational.href}`)}
                  onKeyDown={() => navigate(`${caseRelational.href}`)}
                  key={caseRelational.title}
                >
                  <GatsbyImage image={getImage(caseRelational.icon)} alt="Cases image" />
                  <div className="cases-relational__description" role="none">
                    <p>{caseRelational.title}</p>
                    <p>{caseRelational.description}</p>
                    <a href={caseRelational.href}>Veja mais</a>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              {relationalData?.map((caseRelational) => (
                <div className="cases-relational__slider" key={caseRelational.title}>
                  <GatsbyImage image={getImage(caseRelational.icon)} alt="Cases image" />
                  <div
                    role="none"
                    className="cases-relational__description"
                    onClick={() => navigate(`${caseRelational.href}`)}
                    onKeyDown={() => navigate(`${caseRelational.href}`)}
                  >
                    <p>{caseRelational.title}</p>
                    <p>{caseRelational.description}</p>
                    <a href={caseRelational.href}>Veja mais</a>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="cases-relational__button-container">
        <a aria-label="next" href="/cases" className="cases-relational__button">
          <p>Ver todos os cases</p>
        </a>
      </div>
    </section>
  )
}
export default CasesRelational
